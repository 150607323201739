import { inject, Injectable } from '@angular/core';
import { Socket, SocketIoConfig } from 'ngx-socket-io';
import { Observable } from 'rxjs';
import { AuthService } from '../auth';
import { socketConfig } from './socket-config';

@Injectable({
    providedIn: 'root',
})
export class SocketService {
    private readonly _socket = inject(Socket);
    private readonly _authService = inject(AuthService);

    private _email = '';

    private _initializeSocket(email: string): void {
        this._email = email;

        // Disconnect the current socket
        this._socket.disconnect();

        // Update the socket config with the new email
        const newConfig: SocketIoConfig = {
            ...socketConfig,
            options: {
                ...socketConfig.options,
                query: {
                    userEmail: this._email,
                },
            },
        };

        // Create a new instance of the socket with the updated config
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        this._socket.ioSocket.io.opts.query = newConfig.options?.query;

        // Connect the new socket
        this._socket.connect();

        this._socket.fromEvent('connect').subscribe(() => {
            // console.log('Socket connected:', this._socket.ioSocket);
        });

        this._socket.fromEvent('disconnect').subscribe(() => {
            // console.log('Socket disconnected');
        });
    }

    public init(): void {
        const email = this._authService.currentSession?.email || '';
        this._initializeSocket(email);
    }

    public reinitializeSocket(): void {
        const email = this._authService.currentSession?.email || '';
        this._initializeSocket(email);
    }

    public emit(event: string, data?: unknown): void {
        this._socket.emit(event, data);
    }

    public on<T>(event: string): Observable<T> {
        return this._socket.fromEvent<T>(event);
    }

    public off(event: string): void {
        this._socket.removeAllListeners(event);
    }
}
