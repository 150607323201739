import { environment } from '@env/environment';
import { SocketIoConfig } from 'ngx-socket-io';

export const socketConfig: SocketIoConfig = {
    url: environment.socketUrl,
    options: {
        query: {
            userEmail: '', // This will be dynamically set
        },
    },
};
