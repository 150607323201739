import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy, inject } from '@angular/core';
import { environment } from '@env/environment';
import { DISABLE_GLOBAL_EXCEPTION_HANDLING } from '@lib/interceptors';
import { ChatRoom, ChatRoomResponse, CreateRoomRequest, Message } from '@lib/interfaces';
import { ChatRequest } from '@lib/interfaces/chat-request.interface';
import { ChatUserRegistration } from '@lib/interfaces/chat-user-registration.interface';
import { ChatUser } from '@lib/interfaces/chat-user.interface';
import { SocketErrorHandling } from '@lib/interfaces/socket-io-error-handling.interface';
import { SseClient } from 'ngx-sse-client';
import { BehaviorSubject, Observable, Subject, catchError } from 'rxjs';
import { SocketService } from '../socket/socket.service';
import { VoiceSocketService } from '../voice-socket/voice-socket.service';

@Injectable({
    providedIn: 'root',
})
export class ChatService implements OnDestroy {
    chatRooms$ = new BehaviorSubject<ChatRoomResponse[]>([]);
    isInVoicePage = new BehaviorSubject<boolean>(false);
    isInVoicePage$ = this.isInVoicePage.asObservable();
    isVoiceRoomSidebar = new BehaviorSubject<boolean>(false);
    isVoiceRoomSidebar$ = this.isVoiceRoomSidebar.asObservable();

    setVoiceRoomSidebar(value: boolean): void {
        this.isVoiceRoomSidebar.next(value);
    }

    setInVoicePage(value: boolean): void {
        this.isInVoicePage.next(value);
    }

    getInVoicePage(): boolean {
        return this.isInVoicePage.getValue();
    }
    rateRoomId = '';

    private readonly _http = inject(HttpClient);
    private readonly _socket = inject(SocketService);
    private readonly _voiceSocket = inject(VoiceSocketService);
    private readonly _sseClient = inject(SseClient);
    private readonly _chatEngineUrl = environment.chatEngineUrl;
    private readonly _destroy$ = new Subject();

    public get chatRooms(): ChatRoomResponse[] {
        return this.chatRooms$.getValue();
    }

    ngOnDestroy(): void {
        this._destroy$.complete();
        this._destroy$.unsubscribe();
    }

    sendMessage(chatRequest: ChatRequest): void {
        this._socket.emit('message.create', chatRequest);
    }

    sendVoiceMessage(chatRequest: ChatRequest): void {
        this._voiceSocket.emit('hume.message.create', chatRequest);
    }

    getMessage(): Observable<Message> {
        return this._socket.on<Message>('onMessage');
    }

    getMessageChunk(): Observable<string> {
        return this._socket.on<string>('onMessageChunk');
    }

    errorException(): Observable<SocketErrorHandling> {
        return this._socket.on<SocketErrorHandling>('exception');
    }

    voiceErrorException(): Observable<SocketErrorHandling> {
        return this._voiceSocket.on<SocketErrorHandling>('exception');
    }

    voiceGetMessage(): Observable<Message> {
        return this._voiceSocket.on<Message>('onMessage');
    }

    timerListening(): Observable<number> {
        return this._socket.on<number>('timer');
    }

    // createEventSource(user: SseUser): Observable<MessageResponse> {
    //     let params = new HttpParams();
    //     params = params.set('userEmail', user.userEmail);
    //     const eventSource = new EventSource(environment.socketUrl + '?' + params.toString());

    //     return new Observable((observer) => {
    //         eventSource.onmessage = (event) => {
    //             const messageData: MessageResponse = JSON.parse(event.data);
    //             observer.next(messageData);
    //         };
    //     });
    // }

    // createEventSource(): void {
    //     this._sseClient.stream(this.createEventSource()).subscribe((response) => {
    //         if (response.type === 'error') {
    //             console.log('createEvent');
    //             const errorEvent = response as ErrorEvent;
    //             console.error(errorEvent.error, errorEvent.message);
    //         } else {
    //             const messageEvent = response as MessageEvent;
    //             console.info(messageEvent.type, messageEvent.data);
    //         }
    //     });
    // }

    onViolation(): Observable<Message> {
        return this._socket.on<Message>('onViolation');
    }

    nesyaStartThinking(): Observable<string> {
        return this._socket.on<string>('onThinkingStart');
    }

    nesyaStopThinking(): Observable<string> {
        return this._socket.on<string>('onThinkingStop');
    }

    register(request: ChatUserRegistration): Observable<ChatUser> {
        return this._http.post<ChatUser>(this._chatEngineUrl + '/users', request, {
            context: new HttpContext().set(DISABLE_GLOBAL_EXCEPTION_HANDLING, true),
        });
    }

    createRoom(createRoomRequest: CreateRoomRequest): Observable<ChatRoom> {
        return this._http.post<ChatRoom>(this._chatEngineUrl + '/rooms', createRoomRequest).pipe(
            catchError((error: Error) => {
                return Promise.reject(error);
            }),
        );
    }

    getRooms(userId: string, isSts: boolean): Observable<ChatRoomResponse[]> {
        const params = new HttpParams().set('isSts', isSts.toString());
        return this._http.get<ChatRoomResponse[]>(this._chatEngineUrl + '/rooms/' + userId + '/info', {
            params: params,
        });
    }

    getRoomById(roomId: string): Observable<ChatRoom> {
        return this._http.get<ChatRoom>(this._chatEngineUrl + '/rooms/' + roomId);
    }

    getMessagesByRoomId(roomId: string): Observable<Message[]> {
        return this._http.get<Message[]>(this._chatEngineUrl + '/rooms/' + roomId + '/messages');
    }

    setRateRoomId(roomId: string): void {
        this.rateRoomId = roomId;
    }

    rateSession(request: { ratings?: number | null; feedbackMessage?: string | null }): Observable<unknown> {
        console.log('this.rateRoomId: ', this.rateRoomId);
        return this._http.post<unknown>(this._chatEngineUrl + '/rooms/' + this.rateRoomId + '/ratings', {
            ratings: request.ratings,
            feedbackMessage: request.feedbackMessage,
        });
    }
}
