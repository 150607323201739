import { inject, Injectable } from '@angular/core';
import { Socket, SocketIoConfig } from 'ngx-socket-io';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from '../auth';
import { voiceSocketConfig } from './voice-socket-config';

@Injectable({
    providedIn: 'root',
})
export class VoiceSocketService {
    private readonly _socket = inject(Socket);
    private readonly _authService = inject(AuthService);
    isVoiceRoom = new BehaviorSubject<boolean>(false);
    isVoiceRoom$ = this.isVoiceRoom.asObservable();
    roomId = new BehaviorSubject<string>('');
    roomId$ = this.roomId.asObservable();

    setVoiceRoom(value: boolean): void {
        this.isVoiceRoom.next(value);
    }

    setRoomId(value: string): void {
        this.roomId.next(value);
    }

    getVoiceRoom(): boolean {
        return this.isVoiceRoom.getValue();
    }

    getRoomId(): string {
        return this.roomId.getValue();
    }

    private _email = '';
    private _roomId = '';

    private _initializeVoiceSocket(email: string, roomId: string): void {
        this._email = email;
        this._roomId = roomId;

        // Disconnect the current socket
        this._socket.disconnect();

        // Update the socket config with the new email
        const newConfig: SocketIoConfig = {
            ...voiceSocketConfig,
            options: {
                ...voiceSocketConfig.options,
                query: {
                    userEmail: this._email,
                    roomId: this._roomId,
                },
            },
        };

        // Create a new instance of the socket with the updated config
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        this._socket.ioSocket.io.opts.query = newConfig.options?.query;

        // Connect the new socket
        this._socket.connect();

        this._socket.fromEvent('connect').subscribe(() => {
            this.setVoiceRoom(true);
        });

        this._socket.fromEvent('disconnect').subscribe(() => {
            this.setVoiceRoom(false);
            // console.log('Socket disconnected');
        });
    }

    public initVoice(): void {
        const email = this._authService.currentSession?.email || '';
        const roomId = this.getRoomId();
        this._initializeVoiceSocket(email, roomId);
    }

    public reinitializeVoiceSocket(): void {
        const email = this._authService.currentSession?.email || '';
        const roomId = this.getRoomId();
        this._initializeVoiceSocket(email, roomId);
    }

    public emit(event: string, data?: unknown): void {
        this._socket.emit(event, data);
    }

    public disconnect(): void {
        this._socket.disconnect(true);
    }

    public on<T>(event: string): Observable<T> {
        return this._socket.fromEvent<T>(event);
    }

    public off(event: string): void {
        this._socket.removeAllListeners(event);
    }
}
