import { HttpContextToken, HttpErrorResponse, HttpInterceptorFn, HttpStatusCode } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

export const DISABLE_GLOBAL_EXCEPTION_HANDLING = new HttpContextToken<boolean>(() => false);

/**
 * Interceptor that handles server errors.
 *
 * @param request The request object.
 * @param next The next interceptor in the chain.
 *
 * @returns The next Observable.
 */
export const serverErrorInterceptor: HttpInterceptorFn = (request, next) => {
    const router = inject(Router);

    if (request.context.get(DISABLE_GLOBAL_EXCEPTION_HANDLING)) {
        return next(request);
    } else {
        return next(request).pipe(
            retry(1),
            catchError((error: HttpErrorResponse) => {
                if ([HttpStatusCode.Unauthorized, HttpStatusCode.Forbidden].includes(error.status)) {
                    router.navigateByUrl('/auth/login');
                }

                return throwError(() => error);
            }),
        );
    }
};
